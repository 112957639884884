<template>
  <div class="mian">
    <!-- 交易数据展示模板组件 分为两份,四份,五份,六份 -->
    <div class="ListBox" v-if="dataPanelList.length == 2">
      <div class="itemBox" v-for="(item, index) in dataPanelList" :key="index">
        <div class="itemTitle">{{ item.title }}</div>

        <div v-if="item.type == 1" class="itemValue">
          {{ item.value }}
        </div>
        <div v-if="item.type == 2" class="itemValue">
          {{ (item.value / 100) | formatMoney }}
        </div>
      </div>
    </div>

    <div class="ListBox" v-if="dataPanelList.length == 4">
      <div class="fourCopy" v-for="(item, index) in dataPanelList" :key="index">
        <div class="itemTitle">{{ item.title }}</div>

        <div v-if="item.type == 1" class="itemValue">
          {{ item.value }}
        </div>
        <div v-if="item.type == 2" class="itemValue">
          {{ (item.value / 100) | formatMoney }}
        </div>
      </div>
    </div>
    <div class="ListBox" v-if="dataPanelList.length == 5">
      <div class="fiveCopy" v-for="(item, index) in dataPanelList" :key="index">
        <div class="itemTitle">{{ item.title }}</div>

        <div v-if="item.type == 1" class="itemValue">
          {{ item.value }}
        </div>
        <div v-if="item.type == 2" class="itemValue">
          {{ (item.value / 100) | formatMoney }}
        </div>
      </div>
    </div>
    <div class="ListBox" v-if="dataPanelList.length == 6">
      <div class="sixCopy" v-for="(item, index) in dataPanelList" :key="index">
        <div class="itemTitle">{{ item.title }}</div>

        <div v-if="item.type == 1" class="itemValue">
          {{ item.value }}
        </div>
        <div v-if="item.type == 2" class="itemValue">
          {{ (item.value / 100) | formatMoney }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataPanelList: {
      type: Array,
    },
  },
};
</script>
<style  scoped>
.mian {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.ListBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.itemBox {
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.fourCopy {
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.fiveCopy {
  width: 18.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.sixCopy {
  width: 15.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.itemTitle {
  padding-top: 13px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.itemValue {
  padding-top: 13px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(72, 184, 182, 1);
}
</style>